import { Context } from '@nuxt/types'
import axios from 'axios'

export default async function ({ params, error, redirect }: Context): Promise<void> {
  try {
    const venueSlug = params.venueSlug

    if (!venueSlug) {
      console.log('Error venue validation: venue is undefined')
      redirect('/')
    }

    const venueValidation = validateVenueSlug(venueSlug)

    if (!venueValidation.isValid) {
      console.log('Error venue validation:', venueValidation.errors.join(';'))
      redirect('/')
    }

    const checkVenueResponse = await axios.get<{ data: { exist: boolean } }>(`${process.env.VUE_APP_API_URL}/companies/${venueSlug}/exist`)
    if (!checkVenueResponse.data.data.exist) {
      console.log('Venue does not exist:')
      redirect('/')
    }
  } catch (err) {
    return error({
      statusCode: 500,
      message: 'An error occurred while checking the venue'
    })
  }
}

function validateVenueSlug (venueSlug: string) {
  const errors = []

  // if (venueSlug.length < 3) {
  //   errors.push('Venue slug is too short (minimum 3 characters)')
  // }

  // if (venueSlug.length > 255) {
  //   errors.push('Venue slug is too long (maximum 255 characters)')
  // }

  // if (!/^[a-z0-9-]+$/.test(venueSlug)) {
  //   errors.push('Venue slug can only contain lowercase letters, numbers, and hyphens')
  // }

  if (venueSlug.startsWith('-') || venueSlug.endsWith('-')) {
    errors.push('Venue slug cannot start or end with a hyphen')
  }

  return {
    isValid: errors.length === 0,
    errors
  }
}
