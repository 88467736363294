import { Auth } from '@nuxtjs/auth-next'
import { AxiosError } from 'axios'
import Cookies from 'js-cookie'

// { $auth, redirect, $axios, store, route } : {[k: string] : any}
export default function ({ $auth, redirect, $axios, store, route } : {[k: string] : any}) {
  ($auth as Auth).onError((error: any) => {
    const axiosError : AxiosError = error
    if (axiosError.response?.status === 401) {
      // ($auth as Auth).login()
      ($auth as Auth).logout()
    }
  })
  $axios.onError((error: any) => {
    if (error.response.status === 401) {
      if (route.path.includes('/registrations/')) {
        if (!Cookies.get('isLogin')) {
          redirect('/sign-up')
          Cookies.set('isNewUsers', '1', { path: '/' })
        } else {
          redirect('/sign-in')
          Cookies.set('isExistUsers', '1', { path: '/' })
        }
      } else {
        store.commit('setAuthentication', { authenticated: false, user: null })
        redirect('/sign-in')
      }
    }
  })
}
